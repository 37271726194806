/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Svatby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-x5sl7b js-anim  --anim4 --anim-s3 --full" style={{"paddingBottom":0,"paddingTop":327,"marginBottom":0}} name={"svatbyaoslavy"} fullscreen={true} anim={"4"} animS={"3"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/9cb7fa3e87df46e3b83231933dc245b1_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"marginBottom":0,"paddingTop":95,"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper style={{"marginTop":0,"paddingTop":51,"paddingBottom":0,"maxWidth":1262,"backgroundColor":"rgba(11,10,33,0.1)","marginBottom":0}}>
              
              <Title className="title-box title-box--style2 title-box--shadow4 ff--2 fs--154 w--400 title-box--invert ls--0 lh--12" style={{"paddingTop":11,"marginTop":0}} content={"<span style=\"color: var(--white)\">Svatby a oslavy</span>"}>
              </Title>

              <Text className="text-box ff--1 fs--36 w--300 text-box--invert lh--14" style={{"maxWidth":650,"paddingBottom":40,"marginTop":48}} content={"Dokonalé místo pro Váš šťastný den, zámek Vidžín."}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--shape5 btn-box--cColor1 btn-box--shadow5 fs--20" style={{"backgroundColor":"var(--white)","paddingTop":1,"marginBottom":51,"marginTop":0}} content={"<span style=\"color: rgb(11, 10, 33);\">Proč si vybrat právě nás?</span>"} use={"page"} href={"/svatby#svatbyinfo"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":69,"paddingBottom":50}} name={"svatbyinfo"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"Co u nás najdete"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":1146,"paddingBottom":9,"marginTop":48}} content={"<span style=\"color: var(--color-custom-1);\">Místo, kde se sen stane skutečností. <br>Kouzelné prostředí malého zámku, obklopené přírodou, romantickou zámeckou zahrádkou, plnohodnotným zázemím pro malé i velké oslavy a dostatečnou kapacitou ubytování.&nbsp;<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":54,"backgroundColor":"#e8e5fa","marginBottom":0}} name={"svatbycena"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20 flex--top" style={{"maxWidth":1620,"backgroundColor":"rgba(232,229,250,1)","paddingTop":22}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Cena za svatební víkend na zámku"}>
              </Title>

              <Subtitle className="subtitle-box ff--1 fs--26 w--300" style={{"marginTop":31}} content={"(modelový příklad pro běžný svatební víkend)"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0 pt--0" style={{"paddingBottom":0}}>
              
              <Subtitle className="subtitle-box fs--43 w--400 ls--04 lh--12" content={"<span style=\"font-weight: bold;\">100.000,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 ff--3 fs--20" style={{"backgroundColor":"rgba(87,59,143,1)","marginBottom":24,"marginTop":61}} content={"Co vše je zahrnuto v ceně?"} use={"page"} href={"/svatby#svatbyvcene"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"svatbyvcene"} style={{"paddingBottom":18,"backgroundColor":"#e8e5fa","paddingTop":0}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex el--4 flex--stretch" style={{"maxWidth":1654,"marginTop":0,"paddingTop":0,"paddingBottom":40}} columns={"4"}>
            
            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":90,"backgroundColor":"rgba(247, 247, 247, 1)","paddingRight":38,"paddingLeft":39,"marginTop":70,"paddingTop":30}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/1e467ae366d0481c8d0c9f2655690eb4_s=860x_.png"} style={{"maxWidth":152,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/7361/1e467ae366d0481c8d0c9f2655690eb4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/1e467ae366d0481c8d0c9f2655690eb4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/1e467ae366d0481c8d0c9f2655690eb4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/1e467ae366d0481c8d0c9f2655690eb4_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/1e467ae366d0481c8d0c9f2655690eb4_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginBottom":51}} content={"Místo pro svatební obřad&nbsp;či oslavu<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222,"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;místo pro obřad kdekoliv&nbsp;v zámeckém areálu,&nbsp;v přilehlé zahradě či <br>v barokním altánku.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":84,"backgroundColor":"rgba(247, 247, 247, 1)","paddingRight":32,"paddingLeft":36,"marginTop":70,"paddingTop":35}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/d59b3bd4f1c04f728254949a911c059f_s=860x_.png"} style={{"maxWidth":128,"paddingLeft":0,"paddingTop":0}} srcSet={"https://cdn.swbpg.com/t/7361/d59b3bd4f1c04f728254949a911c059f_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/d59b3bd4f1c04f728254949a911c059f_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/d59b3bd4f1c04f728254949a911c059f_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/d59b3bd4f1c04f728254949a911c059f_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/d59b3bd4f1c04f728254949a911c059f_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":67}} content={"Místo pro svatební<br>hostinu<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222,"marginTop":33}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;soukromý salónek, restaurace vnitřní i venkovní část, velký krytý altán, druhý altán s grilem...</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":87,"backgroundColor":"rgba(247, 247, 247, 1)","paddingLeft":38,"paddingRight":36,"marginTop":72,"paddingTop":22}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/119f7125bd95415c835cc4799ae6064b_s=860x_.png"} style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/7361/119f7125bd95415c835cc4799ae6064b_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/119f7125bd95415c835cc4799ae6064b_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/119f7125bd95415c835cc4799ae6064b_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/119f7125bd95415c835cc4799ae6064b_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/119f7125bd95415c835cc4799ae6064b_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":76}} content={"Ubytování<br>svatebčanů<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222,"marginTop":38}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;pro všechny hosty! V zámku&nbsp;nebo v chatkách. Ubytovací kapacita <br><span style=\"font-weight: bold;\">až 200 osob</span>.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":96,"backgroundColor":"rgba(247, 247, 247, 1)","paddingLeft":34,"paddingRight":29,"marginTop":73,"paddingTop":30}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/6062523b4ebb474691503be565c75ccb_s=860x_.png"} style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/7361/6062523b4ebb474691503be565c75ccb_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/6062523b4ebb474691503be565c75ccb_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/6062523b4ebb474691503be565c75ccb_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/6062523b4ebb474691503be565c75ccb_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/6062523b4ebb474691503be565c75ccb_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":68}} content={"Pokoje<br>pro novomanžele<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222,"marginTop":29}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;&nbsp;3 ložnice <br>ve stylu vídeňského baroka a 16 pokojíčků přímo <br>na zámku (45 lůžek).</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--4 flex--stretch" style={{"maxWidth":1654,"marginTop":0,"paddingTop":0,"paddingBottom":39}} columns={"4"}>
            
            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":90,"backgroundColor":"rgba(247, 247, 247, 1)","paddingRight":38,"paddingLeft":39,"marginTop":70,"paddingTop":30}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/dac8a085ec9c4ad5b06696b6c9ca990f_s=860x_.png"} style={{"maxWidth":152,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/7361/dac8a085ec9c4ad5b06696b6c9ca990f_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/dac8a085ec9c4ad5b06696b6c9ca990f_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/dac8a085ec9c4ad5b06696b6c9ca990f_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/dac8a085ec9c4ad5b06696b6c9ca990f_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/dac8a085ec9c4ad5b06696b6c9ca990f_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginBottom":44,"marginTop":37}} content={"Zámek<br>a zahrady<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":244,"marginTop":28}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;pronájem zámku jako celku včetně zahrad, ubytování a kuchyně na celý víkend.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":84,"backgroundColor":"rgba(247, 247, 247, 1)","paddingRight":32,"paddingLeft":36,"marginTop":70,"paddingTop":35}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/9c684443f5134054945c4f838be7b0f0_s=860x_.png"} style={{"maxWidth":128,"paddingLeft":0,"paddingTop":0}} srcSet={"https://cdn.swbpg.com/t/7361/9c684443f5134054945c4f838be7b0f0_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/9c684443f5134054945c4f838be7b0f0_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/9c684443f5134054945c4f838be7b0f0_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/9c684443f5134054945c4f838be7b0f0_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/9c684443f5134054945c4f838be7b0f0_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":54}} content={"Prostory pro<br>fotografování<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":258,"marginTop":43}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;snoubenci mohou využít zahradu, ale i&nbsp;zámecké&nbsp;komnaty pro zhotovení svatebních fotografií.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":87,"backgroundColor":"rgba(247, 247, 247, 1)","paddingLeft":38,"paddingRight":36,"marginTop":72,"paddingTop":22}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/9dcb81aab8724c6c88cf0af46f9e3f77_s=860x_.png"} style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/7361/9dcb81aab8724c6c88cf0af46f9e3f77_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/9dcb81aab8724c6c88cf0af46f9e3f77_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/9dcb81aab8724c6c88cf0af46f9e3f77_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/9dcb81aab8724c6c88cf0af46f9e3f77_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/9dcb81aab8724c6c88cf0af46f9e3f77_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":60}} content={"Svatební výzdoba <br>a téma"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":258,"marginTop":28}} content={"<span style=\"color: var(--color-custom-1);\">v ceně:&nbsp;zapůjčení základní svatební výzdoby (bílé ubrusy, potahy na židle). Můžete si ale přivézt vlastní dekorace <br>na jakékoliv téma.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":96,"backgroundColor":"rgba(247, 247, 247, 1)","paddingLeft":34,"paddingRight":29,"marginTop":73,"paddingTop":30}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/1a52d9e505fe4dacbbc2943d81621935_s=860x_.png"} style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/7361/1a52d9e505fe4dacbbc2943d81621935_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/1a52d9e505fe4dacbbc2943d81621935_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/1a52d9e505fe4dacbbc2943d81621935_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/1a52d9e505fe4dacbbc2943d81621935_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/1a52d9e505fe4dacbbc2943d81621935_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":46}} content={"Kuchyně<br>a restaurace<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":264,"marginTop":45}} content={"<span style=\"color: var(--color-custom-1);\">v ceně: využití našich restauračních služeb nebo pronájem naší profesionálně vybavené kuchyně <br>(<span style=\"font-weight: bold;\">2 konvektomaty</span>, apod.)</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--4 pb--40 flex--stretch" style={{"maxWidth":1654,"marginTop":0,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":90,"backgroundColor":"rgba(247, 247, 247, 1)","paddingRight":38,"paddingLeft":39,"marginTop":70,"paddingTop":30}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/e16ce9beb9b4477bb2cfc6cf69fdcd0d_s=860x_.png"} style={{"maxWidth":152,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/7361/e16ce9beb9b4477bb2cfc6cf69fdcd0d_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/e16ce9beb9b4477bb2cfc6cf69fdcd0d_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/e16ce9beb9b4477bb2cfc6cf69fdcd0d_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/e16ce9beb9b4477bb2cfc6cf69fdcd0d_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/e16ce9beb9b4477bb2cfc6cf69fdcd0d_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginBottom":44,"marginTop":37}} content={"Místnost<br>pro přípravu<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":244,"marginTop":14}} content={"<span style=\"color: var(--color-custom-1);\">v ceně: zázemí pro nevěstu a ženicha, velmi vítaná možnost zvlášť pro větší svatby a oslavy.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":84,"backgroundColor":"rgba(247, 247, 247, 1)","paddingRight":32,"paddingLeft":36,"marginTop":70,"paddingTop":35}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/321a7f0082954baba97d40941f7a27eb_s=860x_.png"} style={{"maxWidth":128,"paddingLeft":0,"paddingTop":0}} srcSet={"https://cdn.swbpg.com/t/7361/321a7f0082954baba97d40941f7a27eb_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/321a7f0082954baba97d40941f7a27eb_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/321a7f0082954baba97d40941f7a27eb_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/321a7f0082954baba97d40941f7a27eb_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/321a7f0082954baba97d40941f7a27eb_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":54}} content={"Parkování<br>přímo u zámku<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":258,"marginTop":40}} content={"<span style=\"color: var(--color-custom-1);\">v ceně: pronájem parkoviště, které se nachází přímo před branou zámku. Kapacita:&nbsp;<br><span style=\"font-weight: bold;\">až 40 aut nebo 4 autobusy</span>.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":87,"backgroundColor":"rgba(247, 247, 247, 1)","paddingLeft":38,"paddingRight":36,"marginTop":72,"paddingTop":22}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/118fbe7c3fb448ad88b150558a0cef62_s=860x_.png"} style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/7361/118fbe7c3fb448ad88b150558a0cef62_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/118fbe7c3fb448ad88b150558a0cef62_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/118fbe7c3fb448ad88b150558a0cef62_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/118fbe7c3fb448ad88b150558a0cef62_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/118fbe7c3fb448ad88b150558a0cef62_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":60}} content={"Místo pro hudebníky"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":258,"marginTop":50}} content={"<span style=\"color: var(--color-custom-1);\">v ceně: pronájem tanečního parketu, zapůjčení zvukové aparatury, pódium pro koncerty, ...</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --center" style={{"paddingBottom":96,"backgroundColor":"rgba(247, 247, 247, 1)","paddingLeft":34,"paddingRight":29,"marginTop":73,"paddingTop":30}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/bbdcc871e1b14f39b29165eb53172aa4_s=860x_.png"} style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/7361/bbdcc871e1b14f39b29165eb53172aa4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/bbdcc871e1b14f39b29165eb53172aa4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/bbdcc871e1b14f39b29165eb53172aa4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/bbdcc871e1b14f39b29165eb53172aa4_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/bbdcc871e1b14f39b29165eb53172aa4_s=2000x_.png 2000w"} svgColor={"rgba(0,0,0,1)"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px">
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":46}} content={"Dětské herny<br>a pánský salón<br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":264,"marginTop":46}} content={"<span style=\"color: rgb(0, 0, 0);\">v ceně: herna pro děti, herna <br>pro batolata, chata s ping pong stoly, pánský salón - kulečník, fotbálek, ...</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":106}} name={"svatbykontakt"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1116}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Každá svatba je jedinečná <br>– stejně jako Vy! "}>
              </Title>

              <Subtitle className="subtitle-box ff--1 fs--36 w--300" style={{"marginTop":63,"marginBottom":0}} content={"<span style=\"font-weight: bold;\">Neváhejte nám zavolat.</span>"}>
              </Subtitle>

              <Text className="text-box fs--24" style={{"maxWidth":1000,"marginTop":52}} content={"<span style=\"color: var(--color-custom-1);\">Jsme tu, abychom splnili Váš sen o nezapomenutelném zážitku. Přizpůsobíme variabilně naše prostory, upravíme délku pronájmu a nabídneme velmi zajímavou cenu!\n</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 fs--24" style={{"marginTop":48,"backgroundColor":"rgba(87,59,143,1)"}} content={"Nezávazně zavolat"} use={"tel"} href={"tel:+420602625691"} url={"tel:+420602625691"}>
              </Button>

              <Text className="text-box text-box--center fs--24" style={{"marginTop":56}} content={"<span style=\"color: var(--color-custom-1);\">Zámek Vidžín je místo s duší a dávným příběhem, které je klidné, fotogenické a přizpůsobitelné přesně Vašim potřebám. Ať už plánujete intimní obřad nebo velkolepou oslavu, jsme tu pro Vás s otevřeným srdcem a profesionálním přístupem. </span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l8"} name={"ov04jxwm4sc"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/95c67851fd3a40e89fd459d1ff319fb7_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/95c67851fd3a40e89fd459d1ff319fb7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/95c67851fd3a40e89fd459d1ff319fb7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/95c67851fd3a40e89fd459d1ff319fb7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/95c67851fd3a40e89fd459d1ff319fb7_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/00c43733cb854b10a8b1fa229aa7df8b_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/00c43733cb854b10a8b1fa229aa7df8b_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/00c43733cb854b10a8b1fa229aa7df8b_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/00c43733cb854b10a8b1fa229aa7df8b_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/5a2b91b6e8a643a695a7f0cd6f1d5c8c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/5a2b91b6e8a643a695a7f0cd6f1d5c8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/5a2b91b6e8a643a695a7f0cd6f1d5c8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/5a2b91b6e8a643a695a7f0cd6f1d5c8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/5a2b91b6e8a643a695a7f0cd6f1d5c8c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/d073b2bbb7614c12a54d5e4f6a1e7adb_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/d073b2bbb7614c12a54d5e4f6a1e7adb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/d073b2bbb7614c12a54d5e4f6a1e7adb_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/eccc230b68774f008d9942fa4f04ebbc_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/eccc230b68774f008d9942fa4f04ebbc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/eccc230b68774f008d9942fa4f04ebbc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/eccc230b68774f008d9942fa4f04ebbc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/dea7139b01fa44c78319a3118f00712b_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/dea7139b01fa44c78319a3118f00712b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/dea7139b01fa44c78319a3118f00712b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/dea7139b01fa44c78319a3118f00712b_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/91d677c2056e47e2b85b055b8e077af7_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/7361/91d677c2056e47e2b85b055b8e077af7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/91d677c2056e47e2b85b055b8e077af7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/91d677c2056e47e2b85b055b8e077af7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/91d677c2056e47e2b85b055b8e077af7_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#dbd6f3","paddingTop":51,"paddingBottom":46}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" style={{"marginTop":0}} content={"Odkazy"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":334,"marginTop":30}} content={"<a href=\"/nf-vidzin#nfvidzinuvod\" style=\"color: var(--color-custom-1);\">Nadační fond Vidžín</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--22" style={{"marginTop":19}} content={"<a href=\"/#prohlidky\" style=\"color: var(--color-custom-1);\">Prohlídky zámku</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"Zámek Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":311,"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín\n<br>Vidžín č.8, 330 40 Úterý<br><span style=\"font-weight: bold;\">tel:&nbsp;602 625 691</span></span>"}>
              </Text>

              <Image className="--center" style={{"maxWidth":196,"marginTop":27,"paddingLeft":33}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=1400x_.png 1400w"} alt={"logo zámek Vidžín"} src={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"marginTop":0}} content={"Sociální sítě"}>
              </Title>

              <Image style={{"maxWidth":40,"paddingLeft":0,"marginTop":31}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png 350w"} alt={"facebook"} src={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.facebook.com/zameckafaravidzin"} url={"https://www.facebook.com/zameckafaravidzin"}>
              </Image>

              <Image style={{"maxWidth":38,"paddingLeft":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/zamekvidzin/"} url={"https://www.instagram.com/zamekvidzin/"}>
              </Image>

              <Image style={{"maxWidth":42}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.youtube.com/watch?v=aIwoNcyrEC4"} url={"https://www.youtube.com/watch?v=aIwoNcyrEC4"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}